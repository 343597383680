<template lang="pug">
    perfect-scrollbar.content__main(:options="{handlers: ['keyboard', 'wheel'], swipeEasing: false, wheelSpeed: 0.2}")
        create-assistant
</template>

<script>
import CreateAssistant from "../../components/assistants/create/create.vue";

export default {
    name: "CreateAssistantPage",
    components: { CreateAssistant },
};
</script>
