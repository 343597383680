<template lang="pug">
    perfect-scrollbar.content__main(:options="{handlers: ['keyboard', 'wheel'], swipeEasing: false, wheelSpeed: 1}")
        assistants-catalog
</template>

<script>
import MyAssistants from "../../components/assistants/my/my.vue";
import AssistantsCatalog from "@/components/assistants/catalog/catalog.vue";

export default {
    name: "AssistantsPage",
    components: { AssistantsCatalog, MyAssistants },
};
</script>
