<template lang="pug">
    perfect-scrollbar.content__main(:options="{handlers: ['keyboard', 'wheel'], swipeEasing: false, wheelSpeed: 1}")
        my-assistants
</template>

<script>
import MyAssistants from "../../components/assistants/my/my.vue";

export default {
    name: "MyAssistantsPage",
    components: { MyAssistants },
};
</script>
