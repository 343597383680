<template lang="pug">
    .assistants-catalog
        ._title Все GPT ассистенты
            ._search
                ._search-icon-box
                    svg-icon(icon-name="search")._search-icon
                input._search-input(placeholder="Поиск по названию")
        ._list
            ._bot(v-for="bot in bots" :key="bot.personId" v-if="!bot.isDeleted")
                ._bot-head
                    ._bot-avatar-box
                        user-photo(:photo="bot.personAvatar")._bot-avatar
                    ._bot-name {{ bot.personName }}
                ._bot-description {{ bot.botDescription }}
                ._bot-footer
                    ui-button(v-if="isAdmin" type="bordered" color="gray" text="X" size="small" @click.native="confirmDeleteBot(bot)")._bot-add-btn.-delete
                    ui-button(v-if="bot.isStarting" text="Перейти" size="small" type="bordered" color="gold" @click.native="$router.push(`/messages/${MERLIN_CONVERSATION_ID}/themes/assistants/${bot.personId}`);")._bot-add-btn
                    ui-button(v-else text="Добавить себе" size="small" type="bordered" color="gray" @click.native="startConversation(bot.personId)")._bot-add-btn
        infinite-loading(@infinite="getBots")
            Loading(slot="spinner")
</template>

<script>
import gql from "graphql-tag";
import botsListQuery from "@/graphql/queries/botsList.query.graphql";
import getMyAssistantsQuery from "@/graphql/queries/getMyAssistants.query.graphql";
import startConversationQuery from "@/graphql/queries/startConversation.query.graphql";
import deleteChatBotMutation from "@/graphql/mutations/deleteChatBot.mutation.graphql";
import UserPhoto from "@/components/ui/user-photo/user-photo.vue";
import UiButton from "@/components/ui/ui-button/ui-button.vue";
import InfiniteLoading from "vue-infinite-loading";
import botSwitchPublishedMutation from "@/graphql/mutations/botSwitchPublished.mutation.graphql";
import personDeleteMutation from "@/graphql/mutations/personDelete.mutation.graphql";

export default {
    name: "AssistantsCatalog",
    components: { InfiniteLoading, UiButton, UserPhoto },
    data() {
        return {
            bots: [],
            assistants: [],
            page: 1,
            itemsPerPage: 20,
            botToDelete: null,
        };
    },
    async mounted() {
        await this.getMyAssistants();
    },
    methods: {
        confirmDeleteBot(bot) {
            this.botToDelete = bot;
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: "Подтвердите действие",
                text: `Вы действительно хотите удалить ассистента "${bot.personName}"? Отменить данное действие невозможно`,
                buttons: true,
                confirmText: "Удалить",
                callback: this.deleteChatBot,
            });
        },
        async deleteChatBot() {
            await this.disableBot(this.botToDelete.personId);
            await this.deleteBot(this.botToDelete.personId);
            await this.$apollo
                .mutate({
                    mutation: gql(deleteChatBotMutation),
                    variables: {
                        uuid: this.botToDelete.personId,
                    },
                    client: "chatClient",
                })
                .then(() => {
                    this.botToDelete.isDeleted = true;
                    this.botToDelete = null;
                });
        },
        async disableBot(uuid) {
            this.isStarting = true;
            await this.$apollo.mutate({
                mutation: gql(botSwitchPublishedMutation),
                variables: {
                    uuid,
                },
            });
        },
        async deleteBot(uuid) {
            await this.$apollo.mutate({
                mutation: gql(personDeleteMutation),
                variables: {
                    uuid,
                },
            });
        },
        async startConversation(uuid) {
            await this.$apollo
                .query({
                    query: gql(startConversationQuery),
                    variables: {
                        conversationToken: `assistant_${uuid}`,
                    },
                    client: "chatClient",
                })
                .then(() => {
                    this.bots.find(
                        (bot) => bot.personId === uuid,
                    ).isStarting = true;
                });
        },
        async getBots($state) {
            await this.$apollo
                .query({
                    query: gql(botsListQuery),
                    variables: {
                        page: this.page,
                        itemsPerPage: this.itemsPerPage,
                        onlyMyBots: false,
                        published: true,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    const b = [];
                    const list = r.data.botsList.list;

                    if (list.length) {
                        this.page += 1;
                        list.forEach((bot) => {
                            b.push({
                                ...bot,
                                isStarting:
                                    this.assistants.indexOf(bot.personId) >= 0,
                                isDeleted: false,
                            });
                        });
                        this.bots.push(...b);
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                });
        },
        async getMyAssistants() {
            await this.$apollo
                .query({
                    query: gql(getMyAssistantsQuery),
                    fetchPolicy: "no-cache",
                    client: "chatClient",
                })
                .then((r) => {
                    this.assistants = r.data.getMyAssistants;
                });
        },
    },
};
</script>

<style lang="scss" src="./catalog.scss"></style>
