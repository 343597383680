<template lang="pug">
    .create-assistant
        ._title {{ title }}
        ._box
            ._field
                span._label Название бота
                ._input-group(:class="{error: nameError}")
                    input(type="text" v-model="name" @input="assertMaxChars('name', 25)")._input
                    ._input-count {{ name.length }} / 25
                ._input-error(v-if="nameError") Название бота не может быть короче 3 символов
            ._field
                span._label Описание бота
                ._input-group.-textarea(:class="{error: descriptionError}")
                    textarea(v-model="description" @input="assertMaxChars('description', 90)")._input.-textarea
                    ._input-count.-textarea {{ description.length }} / 90
                ._input-error(v-if="descriptionError") Введите описание бота
            ._field.-row
                ._field-group
                    span._label Добавить аватар
                    label(for="avatar" @click.stop="handleLabelClick('avatar')")
                        ui-button(type="bordered" color="gray" size="small")._upload-avatar-btn
                            template(v-slot:content)
                                | Загрузить
                                svg-icon(icon-name="upload" added-catalog="assistant")._upload-avatar-icon
                        input(type="file" @change="uploadAvatar" id="avatar")._input.-file
                        ._input-error(v-if="isNeedAvatarError") Загрузите аватарку бота
                        ._input-error(v-if="isAvatarError") Не удалось установить аватар. Код {{ avatarErrorCode }}
                ._avatar-box
                    loading._avatar-loading(v-if="isAvatarLoading")
                    user-photo(@click.native="handleLabelClick('avatar')" v-else :photo="avatar" :micro="false" :thumbs="false")._avatar
            template(v-if="isEdit")
                ._field
                    span._label Инструкция для бота
                    ._input-group.-textarea
                        textarea(v-model="prompt" @input="assertMaxChars('prompt', 5000)" placeholder="Например: ты помощник в подборе рецептов для правильного питания ")._input.-textarea
                        ._input-count.-textarea {{ prompt.length }} / 5000
            transition(name="fade" appear)
                loading._bot-creating(v-if="isBotCreating")
        ._box(v-if="isEdit")
            ._field
                span._label База знаний
                ._files(v-if="files.length")
                    ._file(v-for="file in files" :key="file.name" v-if="!file.deleted" :class="{error: file.error}")
                        ._file-group
                            ._file-name {{ file.name }}, {{ file.size }}
                            span(v-if="file.error")._file-error  {{ file.error }}
                            span(v-else-if="file.status === 'loading'")._file-status  Файл загружается
                            span(v-else-if="file.status === 'upload' || file.status === 'uploaded'")._file-status  Файл загружен в бота
                            span(v-else-if="file.status === 'processed'")._file-status  Файл загружен в бота
                        span._file-delete(@click="confirmDeleteFile(file)")
                            svg-icon(icon-name="delete")._file-delete-icon
                ui-button(type="bordered" color="gray" size="small" @click.native="handleLabelClick('file')")._upload-avatar-btn
                    template(v-slot:content)
                        | Загрузить файл
                        svg-icon(icon-name="upload" added-catalog="assistant")._upload-avatar-icon
                input(type="file" @change="uploadFile" id="file" accept=".doc, .docx, .pdf, .txt, .csv")._input.-file
                ._files-info Бот будет использовать информацию из загруженных файлов чтобы давать ответы на запросы пользователей.
                    br/br/ К загрузке разрешены файлы: PDF, TXT, DOCX, CSV до 128Mb
        ._box(v-if="isEdit")
            ._field
                span._label Подсказки для пользователей
                ._help(v-for="(help, i) in helpers" :key="i" v-if="help.show")
                    ._input-group
                        input._input(v-model="help.question" placeholder="Вопрос")
                    ._input-group
                        input._input(v-model="help.answer" placeholder="Ответ")
                    ._help-remove(@click="removeHelp(help)") Удалить подсказку
                ui-button(v-if="isNeedMoreHelpers" type="bordered" color="gray" size="small" text="Добавить подсказку" @click.native="addHelp")._add-help
        ._next
            ui-button(type="bordered" @click.native="saveHandler" :disabled="saveLoading")._next-btn
                template(v-slot:content)
                    | {{ btnText }}
                    svg-icon(icon-name="arrow-right" added-catalog="assistant")._next-icon
                    transition(name="fade")
                        loading._save-loading(v-if="saveLoading")
        router-link(to="/assistants/my")._back-link Вернуться к моим ботам
        ._back-link(v-if="isAdmin || IS_DEVELOPMENT" @click="disableBot") Отключить бота
        ui-button(v-if="isAdmin" type="bordered" color="gray" text="Удалить бота" size="small" @click.native="confirmDeleteBot(bot)")._back-link
</template>

<script>
import UserPhoto from "../../ui/user-photo/user-photo.vue";
import UiButton from "../../ui/ui-button/ui-button.vue";
import gql from "graphql-tag";
import getPhotoUploadUrlQuery from "@/graphql/queries/getPhotoUploadUrl.query.graphql";
import getAmazonFileUploadUrl from "@/graphql/queries/getAmazonFileUploadUrl.query.graphql";
import botCreateMutation from "@/graphql/mutations/botCreate.mutation.graphql";
import botEditMutation from "@/graphql/mutations/botEdit.mutation.graphql";
import editChatBotMutation from "@/graphql/mutations/editChatBot.mutation.graphql";
import getBotQuery from "@/graphql/queries/getBot.query.graphql";
import getChatBotQuery from "@/graphql/queries/getChatBot.query.graphql";
import setPersonPhotoMutation from "@/graphql/mutations/setPersonPhoto.mutation.graphql";
import createChatBotMutation from "@/graphql/mutations/createChatBot.mutation.graphql";
import addChatBotFileMutation from "@/graphql/mutations/addChatBotFile.mutation.graphql";
import deleteChatBotFileMutation from "@/graphql/mutations/deleteChatBotFile.mutation.graphql";
import botSwitchPublishedMutation from "@/graphql/mutations/botSwitchPublished.mutation.graphql";
import deleteChatBotMutation from "@/graphql/mutations/deleteChatBot.mutation.graphql";
import personDeleteMutation from "@/graphql/mutations/personDelete.mutation.graphql";

export default {
    name: "CreateAssistant",
    components: { UiButton, UserPhoto },
    data() {
        return {
            name: "",
            nameError: false,
            descriptionError: false,
            isNeedAvatarError: false,
            description: "",
            prompt: "",
            avatar: null,
            oldAvatar: null,
            isAvatarLoading: false,
            trySend: false,
            isBotCreating: false,
            isAvatarError: false,
            avatarErrorCode: null,
            helpers: [
                {
                    id: 1,
                    question: "",
                    answer: "",
                    show: false,
                },
                {
                    id: 2,
                    question: "",
                    answer: "",
                    show: false,
                },
                {
                    id: 3,
                    question: "",
                    answer: "",
                    show: false,
                },
                {
                    id: 4,
                    question: "",
                    answer: "",
                    show: false,
                },
            ],
            files: [],
            saveLoading: false,
            fileToDelete: null,
        };
    },
    computed: {
        bot: function () {
            return {
                personName: this.name,
                personId: this.uuid,
            };
        },
        title: (state) =>
            state.isEdit ? "Редактирование GPT-бота" : "Новый GPT-бот",
        btnText: (state) => (state.isEdit ? "Сохранить" : "Продолжить"),
        uuid: (state) => state.$route.params.uuid,
        isEdit: (state) => !!state.uuid,
        isNeedMoreHelpers: (state) =>
            state.helpers.filter((h) => h.show).length < 4,
    },
    watch: {
        name() {
            if (!this.trySend) return;
            this.nameError = this.name.length < 3;
        },
        description() {
            if (!this.trySend) return;
            this.descriptionError = this.description.length < 3;
        },
    },
    async mounted() {
        if (this.isEdit) {
            await this.getBotInfo();
            await this.getChatBotInfo();
        }
    },
    methods: {
        confirmDeleteBot(bot) {
            this.botToDelete = bot;
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: "Подтвердите действие",
                text: `Вы действительно хотите удалить ассистента "${bot.personName}"? Отменить данное действие невозможно`,
                buttons: true,
                confirmText: "Удалить",
                callback: this.deleteChatBot,
            });
        },
        async deleteChatBot() {
            await this.deleteBot(this.botToDelete.personId);
            await this.$apollo
                .mutate({
                    mutation: gql(deleteChatBotMutation),
                    variables: {
                        uuid: this.botToDelete.personId,
                    },
                    client: "chatClient",
                })
                .then(() => {
                    this.$router.push("/assistants/my");
                });
        },
        async deleteBot(uuid) {
            await this.$apollo.mutate({
                mutation: gql(personDeleteMutation),
                variables: {
                    uuid,
                },
            });
        },
        async disableBot() {
            this.isStarting = true;
            await this.$apollo
                .mutate({
                    mutation: gql(botSwitchPublishedMutation),
                    variables: {
                        uuid: this.uuid,
                    },
                })
                .then(() => {
                    this.isStart = true;
                    this.$store.dispatch(
                        "assistants/setAssistantId",
                        this.assistant.personId,
                    );
                });
        },
        confirmDeleteFile(file) {
            if (file.error) {
                file.deleted = true;
                return;
            }
            this.fileToDelete = file;
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: "Удалить файл?",
                text: `После удаления - бот перестанет учитывать контент файла "${file.name}"`,
                buttons: true,
                confirmText: "Удалить файл",
                callback: this.deleteFile,
            });
        },
        async deleteFile() {
            this.fileToDelete.deleted = true;
            await this.$apollo.mutate({
                mutation: gql(deleteChatBotFileMutation),
                variables: {
                    bot: this.uuid,
                    file: this.fileToDelete.file,
                    file_id: this.fileToDelete.id,
                },
                client: "chatClient",
            });
        },
        addHelp() {
            this.helpers.find((help) => !help.show).show = true;
        },
        removeHelp(help) {
            help.question = "";
            help.answer = "";
            help.show = false;

            const newHelpers = [];
            const showHelpers = this.helpers.filter((h) => h.show);
            const notShowHelpers = this.helpers.filter((h) => !h.show);
            showHelpers.forEach((h, i) => {
                newHelpers.push({
                    id: i + 1,
                    question: h.question,
                    answer: h.answer,
                    show: true,
                });
            });
            notShowHelpers.forEach((h) => {
                newHelpers.push({
                    id: newHelpers.length + 1,
                    question: h.question,
                    answer: h.answer,
                    show: false,
                });
            });

            this.helpers = newHelpers;
        },
        handleLabelClick(label) {
            document.getElementById(label).click();
        },
        async getPhotoUploadUrl() {
            return await this.$apollo
                .query({
                    query: gql(getPhotoUploadUrlQuery),
                })
                .then((r) => {
                    return r.data.getPhotoUploadUrl;
                });
        },
        async getPhotoUploadUrlAmazon() {
            return await this.$apollo
                .query({
                    query: gql(getAmazonFileUploadUrl),
                })
                .then((r) => {
                    return r.data.getAmazonFileUploadUrl;
                });
        },
        async uploadFile({ target: { files = [] } }) {
            const maxFileSize = 128;
            const file = files[0];

            let status = "loading";
            let error = false;

            console.log(file.type);

            if (
                file.type !== "application/msword" &&
                file.type !==
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                file.type !== "text/plain" &&
                file.type !== "application/pdf" &&
                file.type !== "text/csv"
            ) {
                status = "failed";
                error = "Не поддерживаемый тип файла";
            }

            if (file.size / 1024000 > maxFileSize) {
                status = "failed";
                error = `Максимальный размер файла ${maxFileSize}Mb. Файл не будет загружен`;
            }

            const id = new Date().getTime();

            this.files.push({
                id,
                name: file.name,
                size: (file.size / 1024000).toFixed(2) + " Mb",
                error: error,
                status,
                deleted: false,
            });

            /* Если есть ошибки - не запускаем загрузку */
            if (error) return;

            const uploadLink = await this.getPhotoUploadUrlAmazon();

            const xhr = new XMLHttpRequest();
            xhr.open("POST", uploadLink, true);
            xhr.setRequestHeader(
                "Authorization",
                this.$store.getters["auth/token"],
            );

            const $this = this;

            xhr.onreadystatechange = function () {
                if (this.readyState === XMLHttpRequest.DONE) {
                    if (this.status === 200) {
                        let response = JSON.parse(this.responseText);

                        const f = $this.files.find((f) => f.id === id);
                        $this.uploadFileToServer(response.url, file.name);
                        f.status = "upload";
                    } else {
                        console.log("error", this.responseText);
                    }
                }
            };

            xhr.send(file);
        },
        async uploadFileToServer(url, name) {
            await this.$apollo.mutate({
                mutation: gql(addChatBotFileMutation),
                variables: {
                    bot: this.uuid,
                    file: url,
                    name,
                },
                client: "chatClient",
            });
        },
        async uploadAvatar({ target: { files = [] } }) {
            if (!files[0].size) {
                return;
            }
            this.isAvatarLoading = true;

            const url = await this.getPhotoUploadUrl();

            const data = new FormData();

            data.set("file", files[0]);

            let xhr = new XMLHttpRequest();
            xhr.open("POST", url, true);

            xhr.setRequestHeader(
                "Authorization",
                this.$store.getters["auth/token"],
            );

            const $this = this;

            xhr.onreadystatechange = function () {
                if (this.readyState === XMLHttpRequest.DONE) {
                    if (this.status === 200) {
                        let response = JSON.parse(this.responseText);

                        if (!$this.isEdit) {
                            $this.isAvatarLoading = false;
                        }
                        $this.avatar =
                            response.http_host +
                            response.folder_name +
                            response.file_name;
                        
                        $this.isNeedAvatarError = false;

                        if ($this.isEdit) {
                            console.log(response);
                            $this.setAvatar($this.uuid);
                        }
                    } else {
                        console.log("error upload avatar");
                    }
                }
            };

            xhr.send(files[0]);
        },
        async getBotInfo() {
            await this.$apollo
                .query({
                    query: gql(getBotQuery),
                    variables: {
                        uuid: this.uuid,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    const person = r.data.getBot;
                    this.name = person.name;
                    this.avatar = person.avatar;
                    this.oldAvatar = person.avatar;
                    this.description = person.bot_description || "";
                });
        },
        async getChatBotInfo() {
            await this.$apollo
                .query({
                    query: gql(getChatBotQuery),
                    variables: {
                        uuid: this.uuid,
                    },
                    client: "chatClient",
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    const person = r.data.getChatBot;
                    this.prompt = person.prompt;
                    person.files.forEach((file) => {
                        this.files.push({
                            id: file.id,
                            size: (file.size / 1024000).toFixed(2) + " Mb",
                            name: file.name || "Файл",
                            status: file.status,
                            file: file.file,
                            deleted: false,
                        });
                    });
                    if (person.helpers) {
                        this.helpers = JSON.parse(person.helpers);
                    }
                })
                .catch((e) => {
                    if (
                        e.graphQLErrors[0].message ===
                        "No access to edit this bot"
                    ) {
                        this.$msgBox(
                            "Ошибка доступа",
                            "Нет прав для редактирования выбранного бота",
                        );
                        this.$router.push("/assistants/my");
                    }
                });
        },
        async saveHandler() {
            if (this.isEdit) {
                const edit = await this.editBot();
                if(!edit) return;
                this.$msgBox(
                    "Уведомление",
                    "Ассистент был успешно сохранён",
                    "success"
                );
                return this.$router.push(`/messages/${this.MERLIN_CONVERSATION_ID}/themes/assistants/${this.uuid}`);
            }
            await this.createBot();
        },
        async editBot() {
            if (this.name.length < 3) {
                this.nameError = true;
                this.trySend = true;
            }
            if (this.description.length < 3) {
                this.descriptionError = true;
                this.trySend = true;
            }
            if (!this.avatar) {
                this.isNeedAvatarError = true;
                this.trySend = true;
            }

            if(this.descriptionError || this.nameError || this.isNeedAvatarError) {
                return false;
            }
            
            this.saveLoading = true;
            await this.$apollo.mutate({
                mutation: gql(botEditMutation),
                variables: {
                    name: this.name,
                    description: this.description,
                    person_id: this.uuid,
                },
            });
            await this.$apollo.mutate({
                mutation: gql(editChatBotMutation),
                variables: {
                    prompt: this.prompt,
                    helpers: JSON.stringify(this.helpers),
                    uuid: this.uuid,
                    name: this.name,
                },
                client: "chatClient",
            });
            setTimeout(() => {
                this.saveLoading = false;
            }, 1000);
            return true;
        },
        async createBot() {
            if (this.name.length < 3) {
                this.nameError = true;
                this.trySend = true;
            }
            if (this.description.length < 3) {
                this.descriptionError = true;
                this.trySend = true;
            }
            if (!this.avatar) {
                this.isNeedAvatarError = true;
                this.trySend = true;
            }
            
            if(this.descriptionError || this.nameError || this.isNeedAvatarError) {
                return;
            }
            
            this.isBotCreating = true;
            await this.$apollo
                .mutate({
                    mutation: gql(botCreateMutation),
                    variables: {
                        name: this.name,
                        description: this.description,
                    },
                })
                .then(async (r) => {
                    const uuid = r.data.botCreate;

                    if (this.avatar) {
                        await this.setAvatar(uuid);
                    }

                    const avatar = this.avatar ?? "";

                    await this.createChatBot(uuid, this.name, avatar);

                    this.isBotCreating = false;

                    this.redirectToEdit(uuid);
                });
        },
        async setAvatar(uuid) {
            /* Если аватарку не меняли -> оставляем как было */
            if (this.avatar === this.oldAvatar) return;
            setTimeout(async () => {
                await this.$apollo
                    .mutate({
                        mutation: gql(setPersonPhotoMutation),
                        variables: {
                            uuid,
                            photo:
                                this.avatar ||
                                "https://static.merlinface.com/photos/202401/micro/S7BZERQnuwc.jpg",
                            category: "AVATAR",
                        },
                    })
                    .then(async (r) => {
                        const taskId = r.data.setPersonPhoto.taskId;

                        let interval = setInterval(async () => {
                            const taskResult = await this.CHECK_TASK_STATUS(
                                taskId,
                            );

                            /* Если ошибка -> даём ошибку и останавливаем */
                            if (taskResult.status === "ERROR") {
                                this.isAvatarError = true;
                                this.avatarErrorCode = taskResult.error;
                                this.avatar = null;
                                this.isAvatarLoading = false;
                                clearInterval(interval);
                                interval = null;
                            }

                            if (taskResult.status === "COMPLETED") {
                                this.isAvatarLoading = false;
                                clearInterval(interval);
                                interval = null;
                            }
                        }, 2000);
                    });
            }, 500);
        },
        async createChatBot(uuid, name, avatar) {
            await this.$apollo.mutate({
                mutation: gql(createChatBotMutation),
                variables: {
                    uuid,
                    name,
                    avatar,
                },
                client: "chatClient",
            });
        },
        redirectToEdit(uuid) {
            this.$router.push(`/assistants/edit/${uuid}`);
        },
        assertMaxChars(field, maxLength) {
            if (this[field].length >= maxLength) {
                this[field] = this[field].substring(0, maxLength);
            }
        },
    },
};
</script>

<style lang="scss" src="./create.scss"></style>
