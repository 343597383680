<template lang="pug">
    .my-assistants
        ._box
            ._box-title Добро пожаловать в создание GPT-бота от Merlin
            ._box-desc
                p._box-p Мы верим в справедливую монетизацию и политику распределения доходов. 
                p._box-p Когда вы создаете GPT бота у нас, вы можете указать условия монетизации, и мы возьмем 0% от любого дохода от использования, который вы заработаете (без учета всех понесенных комиссий за обработку).
                p._box-p Начните создавать GPT-бота, нажав на кнопку "Создать" ниже
            router-link(to="/assistants/create")._create-link
                ui-button(type="bordered")._create-btn
                    template(v-slot:content)
                        | Создать ассистента
                        svg-icon(icon-name="arrow-right" added-catalog="assistant")._create-icon
        ._box
            ._box-title Ваши боты
            loading._loading(v-if="isLoading")
            template(v-else-if="bots.length")
                ._bot.-titles
                    ._bot-info
                        ._avatar-box.-title Аватар
                        ._name-box Название
                    ._bot-added-info
                        ._use-box Использований
                        ._gain-box Заработано
                        ._status-box Статус
                ._list
                    ._bot(v-for="bot in bots" :key="bot.personId")
                        ._bot-info
                            ._avatar-box
                                user-photo(:photo="bot.personAvatar ")._bot-avatar
                            ._name-box {{ bot.personName }}
                                router-link(:to="`/assistants/edit/${bot.personId}`")._bot-edit Редактировать
                        ._bot-added-info
                            ._use-box {{ bot.botUsedCount }}
                            ._gain-box $0
                            ._status-box
                                template(v-if="!bot.isBotPublished")
                                    ._status-group(v-if="pageWidth > 767")
                                        ui-button(type="bordered" size="small" text="Запустить" @click.native="$modal.show('start-assistant', {assistant: bot})")._start-btn
                                        router-link(:to="`/messages/${MERLIN_CONVERSATION_ID}/themes/assistants/${bot.personId}`")._start Опробовать
                                    ._status-group(v-else)
                                        ._started.-gold(@click="$modal.show('start-assistant', {assistant: bot})") Запустить
                                            svg-icon(icon-name="arrow-top-right" added-catalog="assistant")._started-icon
                                        router-link(:to="`/messages/${MERLIN_CONVERSATION_ID}/themes/assistants/${bot.personId}`")._start Опробовать
                                router-link._started(v-else :to="`/messages/${MERLIN_CONVERSATION_ID}/themes/assistants/${bot.personId}`") Запущен
                                    svg-icon(icon-name="arrow-top-right" added-catalog="assistant")._started-icon
            ._empty(v-else) Вы пока не создали ни одного бота
</template>

<script>
import UiButton from "../../ui/ui-button/ui-button.vue";
import botsListQuery from "@/graphql/queries/botsList.query.graphql";
import gql from "graphql-tag";
import UserPhoto from "../../ui/user-photo/user-photo.vue";

export default {
    name: "MyAssistants",
    components: { UserPhoto, UiButton },
    data() {
        return {
            bots: [],
            isLoading: true,
        };
    },
    computed: {
        enabledAssistantId: (state) =>
            state.$store.getters["assistants/assistantId"],
    },
    watch: {
        enabledAssistantId() {
            const bot = this.bots.find(
                (b) => b.personId === this.enabledAssistantId,
            );

            console.log(bot);

            bot.isBotPublished = true;
        },
    },
    async mounted() {
        await this.getMyBots();
    },
    methods: {
        async getMyBots() {
            await this.$apollo
                .query({
                    query: gql(botsListQuery),
                    variables: {
                        page: 1,
                        itemsPerPage: 20,
                        onlyMyBots: true,
                        published: false
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.bots = r.data.botsList.list;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" src="./my.scss"></style>
